import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ImgTextModule from '../../components/elements/ImgTextModule';
import FadeInAnimation from '../../components/UX/FadeInAnimation';
import MagicText from '../../components/UX/MagicText';
import TeamDuo from '../../components/TeamDuo';
import TeamTri from '../../components/TeamTri';
import KontaktFooter from '../../components/KontaktFooter';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Maik Wieting</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

      {/* CONTAINER MAGICTEXT */}

      <Container minW='100%' mt={0.5} p={0}>
        <MagicText
          text={
            'Wir wollen Karrieren menschlich gestalten helfen und schaffen echte Partnerschaften zwischen Menschen und Unternehmen.'
          }
          quoteOrigin='Maik Wieting'
          fontSize={{ md: '6xl', base: '3xl' }}
          coloranimtext={'#00ffaa'}
          scrollLength={'250vh'}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc='Maik1.mp4'
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={'brand.green'}
          showProgressCircle={true}
          progressColor={'brand.green'}
          showProgressLine={true}
          progressLineColor={'brand.violet'}
        />
      </Container>

      <Box bg='brand.gray.1000'>
        <Container variant='layoutContainer' py={32} px={8}>
          <FadeInAnimation
            threshold={0.1}
            delay={0}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Heading color='brand.violet' fontSize={{ base: '4xl', lg: '6xl' }}>
              Maik Wieting
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text my={4} fontSize={'4xl'} color='brand.green'>
              Personalführung und -entwicklung mit Leidenschaft
            </Text>
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'xl'}
              color='brand.gray.100'
            >
              Als gebürtiger Wilhelmshavener, 71er Jahrgang, bin und bleibe ich
              dem Norden sehr verbunden. Die gesamte Welt mit all ihren
              Herausforderungen verändert sich ständig, so auch die Arbeitswelt.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Nach über 20 Jahren Führungserfahrung im Personalmanagement und in
              der Unternehmensentwicklung ist es an der Zeit, vieles neu zu
              denken. Umdenken, mit Empathie, Respekt und Ehrlichkeit offen zu
              gestalten und vertrauensvoll miteinander umzugehen, das treibt
              mich an. Ich freue mich sehr, ein Teil der "kdw HR" zu sein, nun
              tatsächlich die Werte unternehmerisch einbringen zu können, von
              denen ich ganz persönlich lange schon überzeugt bin.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Bis ganz bald,
              <br />
              <strong>Ihr Maik Wieting</strong>
            </Text>
          </FadeInAnimation>
        </Container>
      </Box>
      <TeamTri />
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
